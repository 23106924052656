import React from "react";
import "./Home.css";

function Home() {
  return (
    <div>
      <div className="homeHead">
        <h1>Home</h1>
      </div>
    </div>
  );
}
export default Home;
